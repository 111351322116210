import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import DialogBox from "../../components/CustomAlert/DialogBox";
import { useNavigate, useParams } from "react-router-dom";

const HrFace2Face = () => {
  // Define a state variable to store the textarea content
  const [object, setObject] = useState( {
    q1YesNo: '',
    q1Comment: '',
    q2YesNo: '',
    q2Comment: '',
    q3YesNo: '',
    q3Comment: '',
    q4YesNo: '',
    q4Comment: '',
    q5YesNo: '',
    q5Comment: '',
    q6YesNo: '',
    q6Comment: '',
  });
  const [modalShow, setModalShow] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Success");
  const [dialogMessage, setDialogMessage] = useState("Success");
  const navigate = useNavigate();

  // Define options for the radio group
  const radioOptions = [
    { value: '1', label: 'Strongly Agree' },
    { value: '2', label: 'Agree' },
    { value: '3', label: 'Strongly Disagree' },
    { value: '4', label: 'Disagree' },
    { value: '5', label: 'Neutral' },
  ];
  const radioOptionsYesNo = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },

  ];

  const fields = [
    { id: '1', mainLabel: 'Please confirm your main reason for leaving is: Career Opportunity - Promotion?', smallLabel:'What were your initial \n' +
          'reasons for joining this \n' +
          'organisation?' },
    { id: '2', mainLabel: 'Where you actively exploring external opportunities?', smallLabel:'Comment' },
    { id: '3', mainLabel: 'Did you feel sufficiently challenged during your time here?', smallLabel:'Comment' },
    { id: '4', mainLabel: 'Did you feel recognised in terms of your contribution to the', smallLabel:'Comment' },
    { id: '5', mainLabel: 'Does this organisation live up to its Brand Promise?', smallLabel:'Comment' },
    { id: '6', mainLabel: 'Would you recommend this organisation as an employer?', smallLabel:'Comment' },
  ];



  // Handle the onChange event
  const handleChange = (event) => {
    const { name, value } = event.target; // Get the name and value of the input that triggered the change
    setObject((prevObject) => ({
      ...prevObject, // Create a copy of the current state
      [name]: value, // Update the specific property based on the input name and value
    }));
  };

  const save = () => {
    setDialogTitle('Success')
    setDialogMessage('Saved successfully')
    setModalShow(true)
  }

  const submit = () => {
    setDialogTitle('Success')
    setDialogMessage('Submitted successfully')
    setModalShow(true)
  }

  const cancel = () => {
    //setModalShow(true)
  }

  return (
    <>
      <Header  icon={"fa-solid fa-users fa-xl mx-2"}  label={"Exit Interviews / HR Face to Face Interview"}/>
      <main id="main" className="main">
        <DialogBox
            type={"success"}
            title={dialogTitle}
            message={dialogMessage}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <h5 className="card-title mx-3">Exit Interview for Wendy Ross</h5>

                  <div className="mx-3">
                    <div className="row">
                      <div className="col-md-8 align-self-start">
                        {fields.map((item) => (
                            <div className={"mb-3"}>
                              <div className={"mt-4 mb-5"}>
                                <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                                  {item.mainLabel}
                                </div>
                                <div className={"ms-5"}>
                                  {radioOptionsYesNo.map((option) => (
                                      <div className="form-check form-check-inline" key={option.value}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`q${item.id}YesNo`}
                                            id={`q${item.id}YesNo-${option.value}`}
                                            value={option.value}
                                            checked={object[`q${item.id}YesNo`] === option.value}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor={`q${item.id}YesNo-${option.value}`}>
                                          {option.label}
                                        </label>
                                      </div>
                                  ))}
                                </div>


                                <div className={"mt-2"}>
                                  <div className={"row"}>
                                    <div className={"col-md-3"}>
                                      <span>{item.smallLabel}</span>
                                    </div>
                                    <div className={"col-md-9"}>

                                  <textarea
                                      className="form-control"
                                      name={`q${item.id}Comment`}
                                      id={`q${item.id}Comment`}
                                      rows="3"
                                      value={object[`q${item.id}Comment`]}
                                      onChange={handleChange}
                                  />
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8 align-self-end">
                      <div className={"d-flex justify-content-end"}>

                        <button
                            className={"btn btn-danger me-2"}
                            onClick={() => { navigate("/") }}
                        >Cancel</button>

                        <button
                            className={"btn btn-secondary me-2"}
                            onClick={save}
                        >Save</button>

                        <button
                            className={"btn btn-success"}
                            onClick={submit}
                        >Submit</button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default HrFace2Face;
