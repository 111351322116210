import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

const HiringManager = () => {
  const radioOptions = ['Strongly Agree', 'Agree', 'Strongly Disagree', 'Disagree', 'Neutral'];
  const navigate =useNavigate()
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys /  Hiring Manager Survey"} />
      <main id="main" className="main">

        <section className="section ">    
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <p>This survey is aimed at evaluating recruiting service levels delivered by Group HR.</p>
                  <h5 className="card-title mx-3">Hiring Manager Survey for Maxwell Mphioe</h5>

                  <div className="mx-3">

                    <div className="row">
                      <div className="col-md-10 align-self-start">
                        <div className={"mb-3"}>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              1. Please indicate the extent to which you believe that the HR Recruiter understood the requirements of the role being recruited for
                            </div>
                            <div className={"ms-5"}>
                              {["Very Well","Sufficiently","Poorly"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio76874"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              2. How would you rate your Recruiter’s ability to advise you adequately?
                            </div>
                            <div className={"ms-5"}>
                              {["Very Well","Sufficiently","Poorly"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio76875"
                                    disabled
                                    id={`radio5-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              3. Were you informed of the SLA regarding Time-to-Fill (date vacancy advertised to the date employment offer accepted)?
                            </div>
                            <div className={"ms-5"}>
                              {["Yes","No"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio76875"
                                    disabled
                                    id={`radio5-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              4. How would you rate your Recruiter’s ability to provide you with quality shortlists of candidates?
                            </div>
                            <div className={"ms-5"}>
                              {["Very Well","Sufficiently","Poorly"].map((item, key) => (
                                  <div className="form-check form-check-inline" key={key}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio76875"
                                        disabled
                                        id={`radio5-${key}`}
                                        defaultChecked={key == 0}
                                    />
                                    <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                      {item}
                                    </p>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              5. To what extent was administration around the new appointment’s take-on efficiently and professionally handled eg. Letter of
                              Appointment and Salary Package Schedule etc.
                            </div>
                            <div className={"ms-5"}>
                              {["Very Well","Sufficiently","Poorly"].map((item, key) => (
                                  <div className="form-check form-check-inline" key={key}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radio76875"
                                        disabled
                                        id={`radio5-${key}`}
                                        defaultChecked={key == 0}
                                    />
                                    <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                      {item}
                                    </p>
                                  </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Please provide us with any general comments and suggestions you may have on how we can improve our recruitment process:
                            </div>
                            <div className={""}>
                              <textarea className="form-control " disabled>

                              </textarea>
                            </div>
                          </div>
                          <p className='text-center font-size-11'>
                            Thank you for your time taken to complete this survey.
                          </p>
                          <div className='d-flex'>
                            <button type="button"  onClick={()=>navigate("/surveys")} class="btn btn-secondary ms-auto me-2 btn-sm  my-2 text-uppercase">Cancel</button>
                            <button type="button" class="btn btn-primary  me-2 btn-sm  my-2 text-uppercase ">Submit</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default HiringManager;
