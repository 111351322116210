import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Dashboard = () => {



  return (
    <>
      <Header label={<h3 className='py-0 my-0 font-style-italic text-dark'>Welcome to the New Hire Surveys</h3>} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className=' '>
                    <p>
                      The primary objective of the New Hires Surveys system is to leverage an automated survey solution to collect valuable data that can enhance processes, increase the sense of value among new hires, and ultimately improve retention and satisfaction levels.
                    </p>
                    <p>
                      We understand that surveys not only gauge the company’s progress in making candidates feel welcome but also contribute to its
                      enhancement.
                    </p>
                  </div>
                  
                  <div className='row'>
                    <div className='col-lg-7 col-md-10 col-sm-8  mx-auto'>
                      <img  className='fluid-image' src='assets/images/home-dargram-2.png' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </main>

    </>
  )
}
export default Dashboard;
