import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

const OnboardingQueastionnaire = () => {
  const radioOptions = ['Strongly Agree', 'Agree', 'Strongly Disagree', 'Disagree', 'Neutral'];
  const navigate =useNavigate()
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys /  Onboarding Survey"} />
      <main id="main" className="main">

        <section className="section ">    
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <p>This survey is aimed at tracking efficiency and make continuous improvements to the onboarding process, after new employees have joined our organisation.</p>
                  <h5 className="card-title mx-3">Onboarding Survey for Maxwell Mphioe</h5>

                  <div className="mx-3">

                    <div className="row">
                      <div className="col-md-10 align-self-start">
                        <div className={"mb-3"}>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            Have any positive impressions that you may have had of our organisation before joining since changed?
                            </div>
                            <div className={"ms-5"}>
                              {["Yes","No","Somewhat"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio76874"
                                    disabled
                                    id={`radio4-${key}`}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <p className='mb-3 mt-0'>If applicable, what prompted this change for you?</p>
                          <div className={""}>
                              <textarea className="form-control " disabled>

                              </textarea>
                            </div>
                          <p className='mb-3 mt-3'>Do you agree with the following:</p>
                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            I have received adequate training and resources to perform my job affectively
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio4ww57657"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            My role responsibilities and expectations were discussed with me by my direct line manager
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio4sd657657"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 2}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            I had a performance contracting discussion with my line manager that has resulted in an agreed performance contract
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio454657657"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>



                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            I was invited to attended the Induction Programme
                            </div>
                            <div className={"ms-5"}>
                              {["Yes","No"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio4"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            What is your experience of our organisation’s culture?
                            </div>
                            <div className={"ms-5"}>
                              {["Great, I fit right in", "Difficult to adapt to at first, but good once you understand it","Still struggling to adapt"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio498536"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            What is your impression of our organisation’s management styles and approach?
                            </div>
                            <div className={"ms-5"}>
                              {["Unapproachable","Consultative","Aggressive"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio43421"
                                    disabled
                                    id={`radio4-${key}`}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                            Any general comments and suggestions you may have on how we can improve our onboarding process:
                            </div>
                            <div className={""}>
                              <textarea className="form-control " disabled>

                              </textarea>
                            </div>
                          </div>
                          <p className='text-center font-size-11'>
                            Thank you for your time taken to complete this survey. You will receive another survey within the three months where you will have another opportunity to provide feedback to us about your on-boarding experience.
                          </p>
                          <div className='d-flex'>
                            <button type="button"  onClick={()=>navigate("/surveys")} class="btn btn-secondary ms-auto me-2 btn-sm  my-2 text-uppercase">Cancel</button>
                            <button type="button" class="btn btn-primary  me-2 btn-sm  my-2 text-uppercase ">Submit</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default OnboardingQueastionnaire;
