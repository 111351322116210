import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const Demographics = () => {

    useEffect(() => {
        const fetchData = async () => {
            try {

            } catch (error) {

            }
        };

        fetchData();
    }, []);

    const chartProps3 = {
        type: 'column',
        title: '',
        categories: ['African', 'Indian', 'Coloured', 'White'],
        colorByPoint: false,
        colors: ['#5BBDCB','#4D91B6'],
        creditsEnabled: false,
        credits:'',
        legendEnabled:false,
        series: [{
            name: 'Intakes',
            data: [52.8, 13.9, 16.0, 17.3],
            dataLabels: {
                enabled: true,
                format: '{y}%',
                style: {
                    color: 'black',
                    textOutline: 'none'
                }
            },
            color: '#5BBDCB',
        },
        ]
    };

    const getOptions = (props) => ({
        chart: {
            type: props.type,
            /*width: props.width===undefined ? 500 : props.width,
            height: props.height===undefined ? 400 : props.height,*/
            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: props.yAxisTitle!==undefined ? props.yAxisTitle : 'Percentages(%)',
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '10px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'justify',
                },
                tickWidth: 0,
            },
        },
        series: props.series,
    });

    return (
        <>
            <Header label={"Dashboards / Demographics"} />
            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <div>
                        <TempFilters />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card'>
                            <div className='card-body'>

                                <div className={"d-flex justify-content-center my-3"}>
                                    <div className="table-container">
                                        <table className="custom-table" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>EMPLOYEE LEVEL</th>
                                                    <th className="text-center">#</th>
                                                    <th>JOB TITLES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td  className={"text-start ms-2"}>Top Management</td>
                                                <td  className={"text-black fst-normal bg-col-4"}>3</td>
                                                <td  className={"text-start text-black fst-normal bg-col-4"}>
                                                    <div className="d-flex flex-column ms-2">
                                                        <span className="job-title">1 x Chief Investment Officer</span>
                                                        <span className="job-title">1 x Director: ECM Direct</span>
                                                        <span className="job-title">1 x Director: Product Development</span>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td className={"text-start ms-2"}>Senior Management</td>
                                                <td className={"text-black fst-normal bg-col-4"}>6</td>
                                                <td className={"text-start text-black fst-normal bg-col-4"}>
                                                    <div className="d-flex flex-column ms-2">
                                                        <span className="job-title">1 x Divisional Director: Compass</span>
                                                        <span className="job-title">2 x Head: Property Development</span>
                                                        <span className="job-title">3 x Enterprise Management Specialist</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td  className={"text-start ms-2"}>Professional / Middle Management</td>
                                                <td  className={"text-black fst-normal bg-col-4"}>39</td>
                                                <td  className={"text-start text-black fst-normal bg-col-4"}>
                                                    <div className="d-flex flex-column ms-2">
                                                        <span className="job-title">17 x Actuarial Specialist</span>
                                                        <span className="job-title">22 x Area Sales Manager</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td  className={"text-start ms-2"}>Professional / Middle Management</td>
                                                <td  className={"text-black fst-normal bg-col-4"}>44</td>
                                                <td  className={"text-start text-black fst-normal bg-col-4"}>
                                                    <div className="d-flex flex-column ms-2">
                                                        <span className="job-title">12 x Actuarial Agent</span>
                                                        <span className="job-title">32 x Actuarial Analysis</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-body'>
                                <div className="mx-3 my-3">
                                    <span className={"fs-4"}>Headcount by</span> <span className={"fs-4 fw-bold"}> Gender</span>
                                </div>
                                <div className={"d-flex flex-column justify-content-center my-3 mx-3"}>
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <span className={"fw-1 fs-1"}><i className="fa-solid fa-person-dress fw-1 fs-1"></i></span> <span className={"fw-1 fs-3"}>60.6%</span>
                                    </div>
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <span className={"fw-1 fs-1"}><i className="fa-solid fa-person fw-1 fs-1"></i></span> <span className={"fw-1 fs-3"}>39.4%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className="mx-3 my-3">
                                    <span className={"fs-4 fw-bold"}> Race</span>
                                </div>
                                <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
export default Demographics;
