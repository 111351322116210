// src/DialogBox.js
import React, {useState} from 'react';
import './DialogBox.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DialogBox = (props) => {

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
           {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header>*/}
            <Modal.Body>
                <center><h4>{props.title}</h4></center>
                <center>
                    <p className={`mt-3 dialog-icon dialog-type-${props.type}`}>
                        {props.type=="success" && <i className="fa-regular fa-circle-check dialog-box-icon spin"></i>}
                        {props.type=="error" && <i className="fa-solid fa-circle-exclamation dialog-box-icon spin"></i>}
                    </p>
                    <p>
                        {props.message}
                    </p>
                </center>
                <div className="d-flex justify-content-center">
                    <Button onClick={props.onHide}>OK</Button>
                </div>

            </Modal.Body>
           {/* <Modal.Footer>

            </Modal.Footer>*/}
        </Modal>
    );
};

export default DialogBox;
