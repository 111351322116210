
import SignIn from './pages/SignIn';

import {BrowserRouter, MemoryRouter, Route, Routes} from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Dashboard from './pages/Dashboard';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';
import config from './config';
import HrFace2Face from './pages/Questionnaires/HrFace2Face';
import LineManager from './pages/Questionnaires/LineManager';
import OnboardingQueastionnaire from './pages/Questionnaires/OnboardingQueastionnaire';
import NewHireQuestionnaire from './pages/Questionnaires/NewHireQuestionnaire';
import Surveys from './pages/Surveys/Surveys';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import IssueLogs from './pages/IssueLog/IssueLog';
import HiringManager from "./pages/Questionnaires/HiringManager";
import HRSurvey from "./pages/Questionnaires/HRSurvey";
import Demographics from "./pages/Dashboards/Demographics";
import NewHireInsights from "./pages/Dashboards/NewHireInsights";
import History from "./pages/Surveys/History";

const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation,setConversation] = useState([]);
  const [question,setQuestion]= useState();
  const token = localStorage.getItem("token");
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = config.apiUrl;
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

const getConversation = ()=>{
  axios.get("getConversations").then((res)=>{
    setConversation(res.data.data)
  }).catch(()=>{

  })
}
const createQuestion = ()=>{
  axios.post("createQuestion",{question}).then((res)=>{
    getConversation();
    setQuestion("");
  }).catch(()=>{

  })
}


  return (
    <>
     
      {
  <button className="chatbot-toggler shadow ">
<span className="material-symbols-rounded">
  <div className="bot-icon-container" onClick={() => { setShowChatbot((val) => { return !val }) }}>
    {showChatbot && <i class="fa-solid fa-xmark text-light"></i>}
    {!showChatbot && <i class="fa-solid fa-message text-light"></i>}
  </div>
  {showChatbot && <div className='position-absolute chatbot-package shadow rounded-4'>
    <Chatbot
      data={chatbotData}
      getConversation={getConversation }
      conversation = {conversation}
      // onClose={()=>{ toggleBot(false)}}
      onChange = {(value)=>{ setQuestion(value)}}
      onClose={() => { }}
      onSubmit={() => { createQuestion() }}
    />
  </div>
  }
</span>
</button>
      }
      <MemoryRouter>
        <Sidebar />
        <Routes >
          <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
          <Route exact path="/surveys/:id/hr-consultant-survey" element={<ProtectedRoutes element={<HrFace2Face />} />} />
          <Route exact path="/surveys/:id/hiring-manager-survey" element={<ProtectedRoutes element={< HiringManager/>} />} />
          <Route exact path="/surveys/:id/hr-survey" element={<ProtectedRoutes element={< HRSurvey/>} />} />
          <Route exact path="/surveys/:id/onboarding-survey" element={<ProtectedRoutes element={<OnboardingQueastionnaire />} />} />
          <Route exact path="/surveys/:id/new-hire-survey" element={<ProtectedRoutes element={< NewHireQuestionnaire/>} />} />
          <Route exact path="/surveys" element={<ProtectedRoutes element={<Surveys />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport/>} />} />
          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs/>} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/demographics" element={<ProtectedRoutes element={<Demographics/>} />} />
          <Route path="/new-hire-insights" element={<ProtectedRoutes element={<NewHireInsights/>} />} />
          <Route path="/history" element={<ProtectedRoutes element={<History/>} />} />
        </Routes>
        <Routes>
          <Route path="/login" element={<Redirect element={< SignIn />} />} />          
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
