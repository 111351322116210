import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Header from "../Header";
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const NewHireInsights = () => {

    useEffect(() => {
        const fetchData = async () => {
            try {

            } catch (error) {

            }
        };

        fetchData();
    }, []);

    const chartProps3 = {
        type: 'column',
        title: '',
        categories: ["Completed", "Not Started", "Viewed", "In-progress"],
        colorByPoint: false,
        colors: ["#67904B", "#547A91", "#DC8E18", "#A6CBDB", "#00164E"],
        creditsEnabled: false,
        credits: '',
        legendEnabled: true,
        series: [
            {
                name: 'Hiring Manager Survey',
                data: [40,20,10,30],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#67904B',
            },
            {
                name: 'New Hire Survey',
                data: [30,20,40,10],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#547A91',
            },
            {
                name: 'Recruiter Survey',
                data: [70,10,10,10],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#DC8E18',
            },
            {
                name: 'Take-on Survey',
                data: [60,20,10,10],
                dataLabels: {
                    enabled: true,
                    format: '{y}%',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
                color: '#00164E',
            }
        ]
    };

    const getOptions = (props) => ({
        chart: {
            type: props.type,

            backgroundColor: 'white',
        },
        plotOptions: {
            column: {
                colorByPoint: props.colorByPoint
            },
            series: {
                borderRadius: '25%'
            }
        },
        colors: props.colors,
        title: {
            text: props.title
        },
        credits: {
            enabled: props.creditsEnabled
        },
        legend: {
            enabled: props.legendEnabled
        },
        yAxis: {
            title: {
                text: 'Percentages(%)',
            },
        },
        xAxis: {
            categories: props.categories,
            labels: {
                style: {
                    fontSize: '12px', // Adjust font size if needed
                    whiteSpace: 'normal', // Allow labels to wrap when long
                    overflow: 'scroll',
                },
                // tickWidth: 0,
            },
        },
        series: props.series,
    });

    const getOptions2 = () => ({
        chart: {
            type: 'column'
        },
        title: {
            text: '',
            align: 'left'
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: ["Completed", "Not Started", "Viewed", "In-progress"]
        },
        colors: ["#67904B", "#547A91", "#DC8E18", "#A6CBDB", "#00164E"],
        yAxis: {
            min: 0,
            title: {
                text: 'Percentage (%)'
            },
            stackLabels: {
                shadow: false,
                enabled: false
            }
        },
        legend: {
            align: 'center',
            floating: false,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    style: {
                        backgroundColor: 'white'
                    }
                }
            }
        },
        series: [
            {
                name: 'Coloured',
                data: [10,30,40,20],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'Indian',
                data: [30,10,20,40],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'White',
                data: [20,40,10,30],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'African',
                data: [40, 20, 30, 10],
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{y}%',
                    color: '#000000',
                    padding: 0,
                    textOutline: 'none',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                }
            },
            ]

    });
    return (
        <>
            <Header label={"Dashboards / New Hire Insights"} />

            <main id="main" className="main">
                <div className='d-flex flex-column'>
                    <div>
                        <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
                        <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
                    </div>
                    <TempFilters />
                </div>
                <section className="section mt-3">
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <HighchartsReact highcharts={Highcharts} options={getOptions2()} />
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <table className="survey-x text-white" width="100%">
                                        <tr>
                                            <td colSpan="6" className="bg-yellow text-black">
                                                Recruitment Channel, Agency & First Impressions
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white description">
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span >Direct</span> <span>Applicant</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span >Manager</span> <span>Referral</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Staff</span> <span>Referral</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Recruitment</span> <span>Agency Referral</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Other</span>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="text-black bg-white">How were you referred to the organisation?</td>
                                            <td className="text-black bg-white text-center">29%</td>
                                            <td className="text-black bg-white text-center">9%</td>
                                            <td className="text-black bg-white text-center">10%</td>
                                            <td className="text-black bg-white text-center">36%</td>
                                            <td className="text-black bg-white text-center">16%</td>
                                        </tr>
                                        <tr className="text-black ">
                                            <td className="description"></td>
                                            <td colSpan={2} className="text-center bg-success">Yes</td>
                                            <td colSpan={2} className="text-center bg-warning">No</td>
                                            <td className="text-center bg-gray text-white">No Response</td>
                                        </tr>
                                        <tr className="text-black ">
                                            <td className="description">Did the agency provide you with sufficient information?</td>
                                            <td colSpan={2} className="text-center bg-white">68%</td>
                                            <td colSpan={2} className="text-center bg-white">8%</td>
                                            <td className="text-center">24%</td>
                                        </tr>

                                        <tr>
                                            <td className="bg-white description"></td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span >Below</span> <span>Average</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Average</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Above</span> <span>Average</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span>Excellent</span>
                                                </div>
                                            </td>
                                            <td className="bg-gray">
                                                <div className="d-flex flex-column justify-content-start align-content-center align-items-center">
                                                    <span >No</span> <span>Response</span>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="text-black bg-white">My experience with the recruitment agency: was poor, below average, average, above average, excellent</td>
                                            <td className="text-black bg-white text-center">0%</td>
                                            <td className="text-black bg-white text-center">24%</td>
                                            <td className="text-black bg-white text-center">16%</td>
                                            <td className="text-black bg-white text-center">60%</td>
                                            <td className="text-black bg-white text-center">0%</td>
                                        </tr>
                                        <tr className="text-black ">
                                            <td></td>
                                            <td colSpan={2} className="text-center bg-success">Yes</td>
                                            <td className="text-center bg-warning">No</td>
                                            <td className="text-center bg-warning">Other</td>
                                            <td className="text-center bg-gray text-white">No Response</td>
                                        </tr>

                                        <tr className="text-black ">
                                            <td>Prior to your engagement with the organisation, did you regard our organisation as an Employer of choice?</td>
                                            <td colSpan={2} className="text-center bg-white">64%</td>
                                            <td className="text-center bg-white">27%</td>
                                            <td className="text-center">2%</td>
                                            <td className="text-center">4%</td>
                                        </tr>

                                        <tr className="text-black ">
                                            <td>Did this impression change after joining the organisation?</td>
                                            <td colSpan={2} className="text-center bg-white">41%</td>
                                            <td className="text-center bg-white">43%</td>
                                            <td className="text-center bg-white">16%</td>
                                            <td className="text-center">0%</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="6" className="bg-yellow text-black">
                                                Interview Stage
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2} className="text-black">
                                                Was the information provided by the agency consistent with the info provided during the interview?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black fw-bold text-center">Yes</td>
                                            <td colSpan={2} className="bg-white text-black fw-bold text-center">No</td>
                                            <td className="bg-white text-black fw-bold text-center">No response</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="bg-white text-black text-center">92%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">4%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">4%</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white text-black">
                                                Describe your experience of the interview process - in particular the following: logistics for the interview was well arranged, parking, directions to venue etc?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">83%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">4%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">13%</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white text-black">
                                                The interviewers were professional?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">92%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">0%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">8%</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white text-black">
                                                The interviewers explained and contextualised
                                                the recruitment process
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">90%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">3%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">7%</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white text-black">
                                                Were your questions adequately answered at the interview?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">93%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">0%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">7%</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-white text-black">
                                                In retrospect would you say that realistic expectations were set during the interview stage?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">94%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">0%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">6%</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="6" className="bg-yellow text-black">
                                                Offer Stage
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2} className="text-black">
                                                Was the information provided to you at the offer stage sufficient to enable you to make a decision?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black fw-bold text-center">Yes</td>
                                            <td colSpan={2} className="bg-white text-black fw-bold text-center">No</td>
                                            <td className="bg-white text-black fw-bold text-center">No response</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="bg-white text-black text-center">91%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">6%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">3%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-black description">
                                                Did you understand how your benefits and remunerations is structured?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">71%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">20%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">9%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-black description">
                                                Was assistance in this regard offered to you?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">71%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">17%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">12%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-black description">
                                                Administration around my take on was efficiently and professionally handled i.e. relevant application forms were timeously provided etc?
                                            </td>
                                            <td colSpan={2} className="bg-white text-black text-center">76%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">11%</td>
                                            <td colSpan={2} className="bg-white text-black text-center">13%</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

        </>
    )
}
export default NewHireInsights;
