import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

const NewHireQuestionnaire = () => {
  // Define options for the radio group
  const radioOptions = ['Strongly Agree', 'Agree', 'Strongly Disagree', 'Disagree', 'Neutral'];
  const navigate =useNavigate()
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Surveys / New Hire Survey"} />
      <main id="main" className="main">

        <section className="section ">    
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <p>This survey is aimed at measuring the efficiency of the recruiting function and quality of communication with candidates.</p>
                  <h5 className="card-title mx-3">New Hire Survey for Maxwell Mphioe</h5>

                  <div className="mx-3">

                    <div className="row">
                      <div className="col-md-10 align-self-start">
                        <div className={"mb-3"}>

                          <div className={"mt-4 mb-3"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              How were you referred to the organisation?
                            </div>
                            <div className={"ms-5"}>
                              {["Direct applicant", "Staff referral", " Recruitment agency referral", " Contractor/Temp conversion", "Other"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio76874"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <p className='mb-3 mt-0'>If you were referred by a recruitment agency, please select the agency:</p>
                          <select class="form-select w-75" aria-label="Default select example" disabled>
                            <option selected>- - - please select - - -</option>

                          </select>
                          <p className='mb-3 mt-3'>Do you agree with the following:</p>
                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              My overall experience with the agency was excellent
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio454657657"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>



                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              The information provided by the agency was consistent with the information provided during the interview
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio4"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 2}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              The logistics for the interview were well arranged and appropriately communicated (parking, interviewers, etc.)
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio498536"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              The information provided at the offer stage was sufficient to enable me to make an informed decision
                            </div>
                            <div className={"ms-5"}>
                              {["Yes", "No"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio43421"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>



                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              I understood how my benefits and remuneration were structured before signing contract
                            </div>
                            <div className={"ms-5"}>
                              {["Yes", "No"].map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio98765"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 1}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>


                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              The administration for my take-on was efficiently and professionally handled i.e. relevant forms timeous, etc
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio4876542we2"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              My overall interaction with the HR Department during my first few weeks was excellent
                            </div>
                            <div className={"ms-5"}>
                              {radioOptions.map((item, key) => (
                                <div className="form-check form-check-inline" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radio6876"
                                    disabled
                                    id={`radio4-${key}`}
                                    defaultChecked={key == 3}
                                  />
                                  <p className="form-check-label mb-0" htmlFor={`radio4-${key}`}>
                                    {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className={"mt-4 mb-5"}>
                            <div className={"w-100 bg-tertiary text-white rounded-1 px-2 py-1 mb-2"}>
                              Please provide us with any general comments and suggestions you may have on how we can improve our process:
                            </div>
                            <div className={""}>
                              <textarea className="form-control " disabled>

                              </textarea>
                            </div>
                          </div>
                          <p className='text-center font-size-11'>
                            Thank you for your time taken to complete this survey. You will receive another survey within the three months where you will have another opportunity to provide feedback to us about your on-boarding experience.
                          </p>
                          <div className='d-flex'>
                            <button type="button"  onClick={()=>navigate("/surveys")} class="btn btn-secondary ms-auto me-2 btn-sm  my-2 text-uppercase">Cancel</button>
                            <button type="button" class="btn btn-primary  me-2 btn-sm  my-2 text-uppercase ">Submit</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default NewHireQuestionnaire;
