import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import { useNavigate } from 'react-router-dom';

const History = () => {
  const navigate = useNavigate()
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"New Survey Hires/ History"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn bg-primary btn-sm py-1 me-2 text-light'>Reset</button>
            <button className='btn bg-primary btn-sm py-1 text-light'>REAPPLY</button>
          </div>
          <TempFilters />
        </div>
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card mt-3'>

                <div className='card-body'>
                <div className='scroll-xs'>

                  <div className='table-contaisner'>
                      <div>
                        <table class="table  custom-table">
                          <thead>
                            <tr>
                              <th scope="col" className=''>Personnel #</th>
                              <th scope="col">Name</th>
                              <th scope="col" className=''>Last Name</th>
                              <th scope="col" className=''>Entry Date</th>
                              <th scope="col" className=''>Line Manager</th>
                              <th scope="col" className=''>New Hire
                                Questionnaire</th>
                              <th scope="col" className=''>Hiring Manager
                                Questionnaire</th>
                              <th scope="col" className=''>HR Consultant
                                Questionnaire</th>
                              <th scope="col" className=''>Onboarding
                                Questionnaire</th>
                              <th scope="col" className=''>Open
                                Questionnaire</th>



                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td >111222</td>
                              <td>Mia</td>
                              <td>Thompson</td>
                              <td>7 May 2023</td>
                              <td>Promise Jones</td>
                              <td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td >120982</td>
                              <td>Mia</td>
                              <td>Thompson</td>
                              <td>7 May 2023</td>
                              <td>Promise Jones</td>
                              <td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td >999333</td>
                              <td>Anele</td>
                              <td>Swanepoel</td>
                              <td>11 May 2023</td>
                              <td>William May</td>
                              <td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td>90988</td>
                              <td>Anele</td>
                              <td>Smith</td>
                              <td>2 Jun 2023</td>

                              <td>Mary Snow</td>
                              <td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td><td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>


                            <tr>
                              <td >444222</td>
                              <td>Hanny</td>
                              <td>White</td>
                              <td>23 Jul 2023</td>

                              <td>Henk Joe</td>
                              <td className='font-weight-600'>Complete</td>
                              <td className='font-weight-600'>Complete</td>
                              <td className='font-weight-600'>Complete</td>
                              <td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td >878787</td>
                              <td>Josh</td>
                              <td>Ross</td>
                              <td>14 Aug 2023</td>
                              <td>Sammy Marks</td>
                              <td onClick={() => { navigate("/surveys/1/new-hire-survey") }} className='font-weight-600 pointer'>Complete</td>
                              <td onClick={() => { navigate("/surveys/1/hiring-manager-survey") }} className='font-weight-600 pointer'>Complete</td>
                              <td onClick={() => { navigate("/surveys/1/hr-survey") }} className='font-weight-600 pointer'>Complete</td>
                              <td onClick={() => { navigate("/surveys/1/onboarding-survey") }} className='font-weight-600 pointer'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td >101010</td>
                              <td>Jane</td>
                              <td>Wick</td>
                              <td>9 Aug 2023</td>

                              <td>Marlyn Thomas</td>

                              <td className='font-weight-600'>Complete</td>
                              <td >Not Started</td>
                              <td className='font-weight-600'>Complete</td>
                              <td >Not Started</td>
                              <td><i className="fa-solid fa-folder-open"></i></td>
                            </tr>

                            <tr>
                              <td >32323</td>
                              <td>Lerato</td>
                              <td>Washington</td>
                              <td>11 Aug 2023</td>

                              <td>Joel Smoth</td>
                              <td className='font-weight-600'>Complete</td>
                              <td >In Progress</td>
                              <td className='font-weight-600'>Complete</td>
                              <td className='font-weight-600'>Complete</td>
                              <td><i class="fa-solid fa-folder-open"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default History;
